import { useEffect, useState } from 'react'
import { db } from '../firebase'

export default function useCollectionGroup(path, orderBy, where = []) {
  const [docs, setDocs] = useState([])
  const [queryField, queryOperator, queryValue] = where

  useEffect(() => {
    let collection = db.collectionGroup(path)

    if (orderBy) {
      collection = collection.orderBy(orderBy)
    }

    if (queryField) {
      collection = collection.where(queryField, queryOperator, queryValue)
    }

    return collection.onSnapshot((snapshot) => {
      const docs = []
      snapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
          parentId: doc.ref.parent.parent.id,
        })
      })
      setDocs(docs)
    })
  }, [path, orderBy, queryField, queryOperator, queryValue])

  return docs
}
