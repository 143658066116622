import JPCovid from '../assets/jp_covid.jpg'
import CovidTestMap from '../assets/covid_test.png'

export default function Covid() {
  return (
    <>
      <div className="text-3xl tracking-wide">COVID-19 Information</div>
      <div className="text-gray-300">Last updated on March 29, 2021</div>
      <img
        className="max-w-screen sm:max-w-lg border mt-4"
        src={JPCovid}
        alt="Perry & John"
      />
      <div className="text-gray-300 sm:max-w-lg max-w-screen text-left leading-5 space-y-4">
        <p>
          In a pre-COVID world we would have loved to see each and every one of
          you on our wedding day in person. Unfortunately, the reality is that
          there are still serious risks associated with traveling and attending
          large gatherings. Due to this, in addition to our in-person event, we
          will be providing the option for guests to join the live-stream of the
          ceremony via Zoom.
        </p>
        <p>
          For those who do attend in person, we are going to do everything we
          can to ensure the safety of our guests. We encourage{' '}
          <strong>everyone</strong> to get tested for COVID prior to the wedding
          (see details below for convenient test sites).
        </p>
        <p>
          We will be adhering to the following rules throughout the duration of
          the event:
        </p>
        <p>
          <b>Masks</b>
          <br />
        </p>
        <div>
          - All guests must be masked upon arrival and throughout the ceremony.
        </div>
        <div>
          - Guests must remain masked at all times unless seated at a table
          while eating or drinking.
        </div>
        <p />
        <p>
          <b>Social Distancing</b>
          <br />
        </p>
        <div>- Refrain from hugs, handshakes, and highfives.</div>
        <div>- Maintain a safe distance from others.</div>
        <p />
        <p>
          <b>Sanitizer</b>
          <br />
        </p>
        <div>
          - Hand sanitizer will be readily available throughout the venue space.
        </div>
        <div>- Please wash and sanitize frequently.</div>
        <p />
        <p>
          We appreciate your cooperation. Please monitor this website for any
          updates and/or changes and feel free to contact us directly with any
          questions at{' '}
          <a className="underline" href="mailto:wedding@perryandjohn.com">
            wedding@perryandjohn.com
          </a>
          .
        </p>
        <p>Thank you for your love, patience and concern.</p>
      </div>
      <div className="text-xl pb-4 pt-4 sm:max-w-lg max-w-screen w-full">
        <div className="text-3xl tracking-wide pb-2">COVID Test Sites</div>
        <ul className="space-y-8 text-gray-200">
          <li>
            <a
              className="border bg-gray-800 m-2 focus:outline-none h-10 flex justify-center items-center"
              rel="noreferrer"
              target="_blank"
              href="https://a.flexbooker.com/widget/67a9bda2-bd71-43cb-aead-9015cb033887?locationId=20026#chooseService"
            >
              <span className="font-light uppercase tracking-wider">
                Collection Sites
              </span>
            </a>
            <div className="text-gray-300 text-base leading-5 flex flex-col items-center">
              (Across the street from the Marriott Hotel, on the top floor of
              the 2-story parking garage next to Nordstrom)
              <br />
              <span>
                <a
                  target="_blank"
                  href="https://goo.gl/maps/pzWAoh6GugcJ2SXq7"
                  rel="noreferrer"
                  className="underline hover:no-underline"
                >
                  <img
                    className="max-w-screen sm:max-w-md border mt-4"
                    src={CovidTestMap}
                    alt="Covid Test Map"
                  />
                  Click here for a dropped pin.
                </a>{' '}
              </span>
              <br />
              <br /> 7am-9pm <br /> (Appointment required)
              <br />
              <br />
              <ul>
                <li>Rapid Test (10 minutes)</li>
                <li>Molecular PCR Test (48 hours)</li>
              </ul>
            </div>
          </li>
          <li>
            <a
              className="border bg-gray-800 m-2 focus:outline-none h-10 flex justify-center items-center"
              rel="noreferrer"
              target="_blank"
              href="https://norcalcovid19testing.com/"
            >
              <span className="font-light uppercase tracking-wider">
                CityHealth - Dublin
              </span>
            </a>
            <div className="text-gray-300 text-base leading-5">
              (5 miles from the hotel)
              <br />
              5380 Broder Blvd
              <br /> Dublin, CA 94568
              <br />
              <br /> 10am-7pm <br /> (Appointment required)
              <br />
              <br />
              <ul>
                <li>Molecular PCR Test (48 hours)</li>
              </ul>
            </div>
          </li>
          <li>
            <a
              className="border bg-gray-800 m-2 focus:outline-none h-10 flex justify-center items-center"
              rel="noreferrer"
              target="_blank"
              href="https://norcalcovid19testing.com/"
            >
              <span className="font-light uppercase tracking-wider">
                CityHealth - Livermore
              </span>
            </a>
            <div className="text-gray-300 text-base leading-5">
              (5 miles from the venue)
              <br />
              3000 Campus Hill Dr
              <br /> Livermore, CA 94551
              <br />
              <br /> 8:30am-4:30pm <br /> (Appointment required)
              <br />
              <br />
              <ul>
                <li>Molecular PCR Test (48 hours)</li>
              </ul>
            </div>
          </li>
          <li>
            <a
              className="border bg-gray-800 m-2 focus:outline-none h-10 flex justify-center items-center"
              rel="noreferrer"
              target="_blank"
              href="https://www.cvs.com/minuteclinic/covid-19-testing?icid=mc-homepage-banner2-poct"
            >
              <span className="font-light uppercase tracking-wider">
                CVS Pharmacy
              </span>
            </a>
            <div className="text-gray-300 text-base leading-5">
              (5 miles from the venue)
              <br />
              <br />
              <ul>
                <li>Rapid Test (hours)</li>
                <li>Drive-thru Lab Tests (1-2 days)</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}
