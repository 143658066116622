import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

var config = {
  apiKey: 'AIzaSyB-yYapMdHJTMUBH4JcCaq_ucUBF_T4QpY',
  authDomain: 'perry-and-john.firebaseapp.com',
  databaseURL: 'https://perry-and-john.firebaseio.com',
  projectId: 'perry-and-john',
  storageBucket: 'perry-and-john.appspot.com',
  messagingSenderId: '953569799301',
  appId: '1:953569799301:web:ac90f52f5cd86bd3558dec',
  measurementId: 'G-LQVZDL12G3',
}

firebase.initializeApp(config)

const db = firebase.firestore()
const functions = firebase.functions()

if (window.location.hostname === 'localhost') {
  functions.useEmulator('localhost', 5001)
  db.useEmulator('localhost', 8080)
}

export { db, firebase, functions }
