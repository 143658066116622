import { differenceInCalendarDays } from 'date-fns'
import HomeImg from '../assets/home_img.jpg'

export default function Home() {
  const wedding = new Date(2021, 3, 3)
  const numDays = differenceInCalendarDays(wedding, new Date())

  return (
    <>
      <div className="text-gray-100">
        Update (4/3/21): The link has been added to the{' '}
        <a className="underline" href="/live">
          Live Stream
        </a>{' '}
        page.
      </div>
      <div className="text-gray-100">
        Update (3/29/21): Shuttle times have been added to the{' '}
        <a className="underline" href="/travel">
          Travel
        </a>{' '}
        page.
      </div>

      <div className="text-2xl tracking-widest">4.3.21</div>
      <div className="text-gray-300">Today is the day!</div>

      <img
        className="m-6 object-contain max-w-screen md:max-w-2xl border"
        src={HomeImg}
        alt="Perry & John"
      />

      <div className="text-2xl tracking-wider mb-8">
        Perry Constas
        <br />&<br />
        John Hartquist
      </div>

      <div className="text-gray-300 mb-8">
        <div>April 3, 2021</div>
        <div>Pleasanton, CA</div>
      </div>
    </>
  )
}
