import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { BsBoxArrowInUpRight } from 'react-icons/bs'

export default function Nav() {
  const [showMenu, setShowMenu] = useState(true)
  const buttonHandler = () => setShowMenu((showMenu) => !showMenu)

  return (
    <header className="pt-3 sm:border-b border-gray-600">
      <button
        className="sm:hidden absolute left-3 top-4 text-gray-300 transform rotate-90 focus:outline-none"
        onClick={buttonHandler}
      >
        |||
      </button>
      <Link
        to="/"
        className="text-4xl tracking-widest font-light mb-2 uppercase"
      >
        Perry & John
      </Link>
      <nav
        className={`${
          showMenu ? 'flex' : 'hidden'
        } flex-col justify-center uppercase tracking-wider sm:flex-row sm:flex`}
      >
        <NavLink to="/" exact>
          Home
        </NavLink>
        <NavLink to="/events">Events</NavLink>
        <NavLink to="/travel">Travel</NavLink>
        <NavLink to="/photos">Photos</NavLink>

        <NavLink to="/covid">COVID-19</NavLink>
        {/* <NavLink to="/rsvp">RSVP</NavLink> */}
        <a
          href="https://www.zola.com/registry/perryandjohn"
          target="_blank"
          rel="noreferrer"
        >
          Registry <BsBoxArrowInUpRight className="inline" />
        </a>
        <NavLink to="/live">Live Stream</NavLink>
      </nav>
    </header>
  )
}
