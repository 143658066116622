import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/Home'
import Events from './components/Events'
import Photos from './components/Photos'
import Covid from './components/Covid'
// import RSVP from './components/RSVP'
import Travel from './components/Travel'
import Nav from './components/Nav'
import Admin from './components/Admin'
import LiveStream from './components/LiveStream'

function App() {
  return (
    <Router>
      <div className="min-h-screen sm:h-screen bg-black flex flex-col text-white text-center font-serif tracking-wide text-lg">
        <Nav />
        <main className="p-6 pb-20 flex flex-col items-center w-full overflow-y-auto space-y-8">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/events">
              <Events />
            </Route>
            <Route path="/travel">
              <Travel />
            </Route>
            <Route path="/photos">
              <Photos />
            </Route>
            <Route path="/covid">
              <Covid />
            </Route>
            {/* <Route path="/rsvp">
              <RSVP />
            </Route> */}
            <Route path="/adminPCH">
              <Admin />
            </Route>
            <Route path="/live">
              <LiveStream />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  )
}

export default App
