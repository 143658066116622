import useCollection from '../hooks/useCollection'
import useCollectionGroup from '../hooks/useCollectionGroup'
import _ from 'lodash'

export default function Admin() {
  const groups = useCollection('groups', 'name')
  const users = useCollectionGroup('users')
  const usersByGroup = _.groupBy(users, 'parentId')
  groups.forEach((group) => {
    group.users = usersByGroup[group.id]
  })

  return (
    <div className="flex flex-col text-left w-full text-base font-sans">
      {groups.map((group) => (
        <div key={group.id} className="flex flex-row py-4 border-gray-500">
          <div className="w-80">{group.name}</div>
          <div className="">
            <ul>
              {group.users &&
                group.users.map((user) => (
                  <li key={user.id}>
                    <span className="inline-block w-40">{user.name}</span>
                    <span className="inline-block w-12">
                      {user.attending || '-'}
                    </span>
                    <span className="inline-block w-20">
                      {user.entree || '-'}
                    </span>
                    <span className="inline-block w-64">
                      {user.dietary || '-'}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="w-48">{group.email}</div>
        </div>
      ))}
    </div>
  )
}
