export default function Events() {
  return (
    <div className="space-y-4">
      <div className="text-3xl tracking-wide">Events</div>

      <div className="text-2xl pt-4">Wedding Ceremony & Reception</div>
      <div className="text-gray-300">
        <div>The Palm Event Center</div>
        <div>1184 Vineyard Ave</div>
        <div>Pleasanton, CA 94566</div>
      </div>

      <div className="text-gray-300 space-y-3">
        <p>3:00 PM</p>
        <p>Semi-Formal Cocktail Attire</p>
      </div>
      <div className="pt-4">
        <a
          className="border bg-gray-800 px-3 py-2 focus:outline-none"
          rel="noreferrer"
          target="_blank"
          href="https://goo.gl/maps/pst577KNXRPZ7tPAA"
        >
          <span className="font-light uppercase tracking-wider">
            {' '}
            Get Directions{' '}
          </span>
        </a>
      </div>

      <div className="text-2xl pt-8">After Party</div>
      <div className="text-gray-300">
        <p>Marriot AC Pleasanton Patio Lounge (by pool)</p>
        <p>5990 Stoneridge Mall Rd</p>
        <p>Pleasanton, CA 94588</p>
      </div>

      <div className="pt-4">
        <a
          className="border bg-gray-800 px-3 py-2 focus:outline-none"
          rel="noreferrer"
          target="_blank"
          href="https://goo.gl/maps/HBGBhEx6Zctwid7n7"
        >
          <span className="font-light uppercase tracking-wider">
            {' '}
            Get Directions{' '}
          </span>
        </a>
      </div>
    </div>
  )
}
