export default function LiveStream() {
  return (
    <div className="space-y-4 max-w-xl">
      <div className="text-3xl tracking-wide">Virtual Live Stream</div>

      <div>
        <a
          className="border bg-gray-800 m-2 focus:outline-none h-10 flex justify-center items-center"
          rel="noreferrer"
          target="_blank"
          href="https://us02web.zoom.us/j/81349377871?pwd=eXVpRGpjSnJSNGloVVV6S3hUNHQzUT09"
        >
          <span className="font-light uppercase tracking-wider">
            Join Live Stream
          </span>
        </a>
        <div className="text-xs">
          <p>Meeting ID: 813 4937 7871 </p>
          <p>Passcode: 427175</p>
        </div>
      </div>

      <div className="text-2xl pt-4">Timeline</div>
      <div className="text-gray-300">
        <div>Live stream opens at 2:30pm PST</div>
        <div>Ceremony begins at 3:00pm PST</div>
      </div>

      <div className="text-2xl pt-4">Information</div>
      <div className="text-gray-300">
        <div>
          We will be streaming the ceremony using Zoom, and will send out the
          link via e-mail on Thursday, April 1st. We will also post the link
          here on Saturday morning. If you would like to be added to the e-mail
          list, please contact us at{' '}
          <a className="underline" href="mailto:wedding@perryandjohn.com">
            wedding@perryandjohn.com
          </a>
          .
        </div>
      </div>
    </div>
  )
}
