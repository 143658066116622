export default function Travel() {
  return (
    <>
      <div className="text-xl pb-4 pt-4 max-w-lg">
        <div className="text-3xl tracking-wide pb-2">Transportation</div>
        <ul className="space-y-6 text-gray-200">
          <li>
            <span>Shuttles</span>
            <div className="text-gray-300 text-lg leading-5 space-y-4">
              <span>
                Shuttle service will be provided between the hotel and the venue
                before the ceremony and after the reception.
              </span>

              <div>
                <u>Departing from AC Hotel</u>
                <div>2:00pm and 2:30pm</div>
              </div>
              <div>
                <u>Departing from Palm Event Center</u>
                <div> 9:00pm and 9:30pm</div>
              </div>
            </div>
          </li>
          <li>
            <span>Parking</span>
            <div className="text-gray-300 text-lg leading-5">
              Parking is available at both the hotel and the venue.
            </div>
          </li>
          <li>
            <span>Rideshare</span>

            <div className="text-gray-300 text-lg leading-5">
              Uber and Lyft are readily available in Pleasanton.
            </div>
          </li>
        </ul>
      </div>

      <div className="text-xl pb-4 max-w-2xl">
        <div className="text-3xl tracking-wide pb-2">Airports</div>
        <ul className="space-y-4 text-gray-200">
          <li>
            <span>Oakland Airport (OAK)</span>
            <div className="text-gray-300 text-lg">(most convenient)</div>
          </li>
          <li>
            <span>San Jose Airport (SJC)</span>
          </li>
          <li>
            <span>San Francisco Airport (SFO)</span>
          </li>
        </ul>
      </div>

      <a
        className="border bg-gray-800 m-2 px-3 py-4 w-64 h-10 focus:outline-none flex justify-center items-center"
        rel="noreferrer"
        target="_blank"
        href="https://goo.gl/maps/pst577KNXRPZ7tPAA"
      >
        <span className="font-light uppercase tracking-wider text-base">
          Directions to Venue
        </span>
      </a>

      <div className="text-xl ">
        <div className="text-3xl tracking-wide pt-8 pb-2">Hotel</div>

        <span className="text-gray-200">Marriot AC Pleasanton</span>
        <div className="text-gray-300 text-lg max-w-lg text-center leading-5 mt-2 space-y-4">
          <p>
            Due to COVID restrictions, at this time hotel rooms may only be
            booked through the link below. This block is specific for
            reservations that fall between Thursday, 4/1/21 and Monday, 4/5/21.
            If you are interested in booking outside of these dates please reach
            out to us directly at{' '}
            <a className="underline" href="mailto:wedding@perryandjohn.com">
              wedding@perryandjohn.com
            </a>
            .
          </p>
        </div>
      </div>
      <a
        className="border bg-gray-800 m-2 px-3 py-4 w-48 h-10 focus:outline-none flex justify-center items-center"
        rel="noreferrer"
        target="_blank"
        href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1594489220438&key=GRP&app=resvlink"
      >
        <span className="font-light uppercase tracking-wider">Book a room</span>
      </a>
    </>
  )
}
