import Img1 from '../assets/img1.jpg'
import Img2 from '../assets/img2.jpg'
import Img3 from '../assets/img3.jpg'
import Img4 from '../assets/img4.jpg'
import Img5 from '../assets/img5.jpg'
import Img6 from '../assets/img6.jpg'
import Img7 from '../assets/img7.jpg'

export default function Photos() {
  const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7]

  return (
    <div className="space-y-6 pt-4">
      {images.map((image, index) => (
        <img
          className="max-w-screen md:max-w-2xl border"
          key={index}
          src={image}
          alt={`${index}`}
        />
      ))}
    </div>
  )
}
